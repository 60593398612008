import { Form } from 'react-bootstrap'
import React from 'react'
import PropTypes from 'prop-types'

export const TextInput = ({ inputId, answer, onValueChange, onBlur, fieldRef, question }) => (
    <Form.Control
        id={inputId}
        type="text"
        value={answer?.value}
        onChange={onValueChange}
        disabled={answer?.disabled}
        onBlur={onBlur}
        ref={fieldRef}
        placeholder={question.type === 'text' ? 'Textantwort' : 'Zahl'}
        maxLength="2000"
    />
)
TextInput.propTypes = {
    inputId: PropTypes.any,
    answer: PropTypes.any,
    onValueChange: PropTypes.func,
    onBlur: PropTypes.func,
    question: PropTypes.any,
    fieldRef: PropTypes.any
}
