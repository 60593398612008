import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Table from '../components/Table'
import api from '../services/api'
import { Context } from '../store'
import Legend from '../components/Legend'

const Surveys = () => {
    const { id } = useParams()
    const [survey, setSurvey] = useState({})
    const [, dispatch] = useContext(Context)

    useEffect(() => {
        api.getSurveys(id).then(survey => {
            setSurvey(survey)
            dispatch({ type: 'SET_ANSWERS', payload: survey.answers ?? {} })
        })
    }, [id, dispatch])

    return (
        <>
            <Table survey={survey}/>
            {survey.id && <Legend />}
        </>
    )
}

export default Surveys
