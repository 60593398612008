import React from 'react'
import ReactPopover from 'react-bootstrap/Popover'
import Alert from 'react-bootstrap/Alert'
import { Button } from 'react-bootstrap'
import { useAnswers } from '../hooks/useAnswers'

export const Popover = React.forwardRef(({ popper, surveyId, product, question, resubmit, ...props }, ref) => {
    const style = {
        ...props.style,
        position: 'absolute'
    }
    const { getAnswer } = useAnswers()
    const answer = getAnswer(product.id, question.id)
    return (
        <ReactPopover ref={ref} {...props} style={style}>
            <ReactPopover.Header as="h3">Beantwortet</ReactPopover.Header>
            <ReactPopover.Body>
                {answer?.errors && (
                    <Alert variant="danger">
                        {answer.errors.map(error => (
                            <p>{error}</p>
                        ))}
                        <Button variant="primary" onClick={resubmit}>Erneut senden</Button>
                    </Alert>
                )}
                {!answer?.errors && (
                    <>
                        <p>{answer.contact}</p>
                        <p>{answer.created}</p>
                    </>
                )}
            </ReactPopover.Body>
        </ReactPopover>
    )
})
