const Reducer = (state, action) => {
    switch (action.type) {
    case 'SET_ANSWER': {
        const newState = structuredClone(state)
        const isMultiSelect = action.payload.question_type === 'multiselect'
        const { product_id: productId, question_id: questionId } = action.payload
        const value = isMultiSelect ? action.payload.value.map(item => item.value) : action.payload.value

        newState.answers[productId] = newState.answers[productId] ?? {}
        newState.answers[productId][questionId] = {
            ...action.payload,
            value
        }
        return newState
    }
    case 'SET_ANSWERS':
        return {
            ...state,
            answers: action.payload
        }
    default:
        return state
    }
}

export default Reducer
