import { Form } from 'react-bootstrap'
import React from 'react'
import PropTypes from 'prop-types'

export const SelectInput = ({ inputId, answer, onValueChange, onBlur, fieldRef, question }) => {
    return (
        <Form.Select
            id={inputId}
            value={answer?.value}
            disabled={answer?.disabled}
            onChange={onValueChange}
            onBlur={onBlur}
            ref={fieldRef}
        >
            <option value="">Auswahl</option>
            {question.options?.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Form.Select>
    )
}

SelectInput.propTypes = {
    inputId: PropTypes.any,
    answer: PropTypes.any,
    onValueChange: PropTypes.func,
    onBlur: PropTypes.func,
    question: PropTypes.any,
    fieldRef: PropTypes.any
}
