import React from 'react'
import PropTypes from 'prop-types'
import { Table as ReactTable } from 'react-bootstrap'
import Field from './Field/Index'
import styles from './Table.module.scss'
import Hierarchy from './Hierarchy'
import ReportField from './ReportField'

const Table = ({ survey, isReport = false }) => {
    if (!survey?.products || survey.products.length === 0) return null
    return (
        <ReactTable className={styles.table} striped bordered >
            <thead>
                <tr>
                    <th/>
                    {survey.questions.map((question, index) => (
                        <th scope="col" key={index}>{question.text}</th>
                    ))
                    }
                </tr>
            </thead>
            <tbody>
                {survey.products.map((product, index) => (
                    <tr key={index}>
                        <th scope="row">
                            {!isReport && <Hierarchy parents={product?.parent_names || []}></Hierarchy>}
                            <p>{product.name}</p>
                        </th>
                        {survey.questions.map((question, index) => {
                            const highlightCell = isReport && survey.answers?.[product.id]?.[question.id]?.from_earlier_survey === false
                            return (
                                <td key={index} className={ highlightCell ? styles.highlight : undefined}>
                                    {isReport
                                        ? (
                                            <ReportField
                                                answer={survey.answers?.[product.id]?.[question.id]}
                                            />
                                        )
                                        : <Field surveyId={survey.id} product={product} question={question}/>}
                                </td>
                            )
                        }
                        )}
                    </tr>
                ))}
            </tbody>
        </ReactTable>
    )
}

Table.propTypes = {
    survey: PropTypes.object,
    isReport: PropTypes.bool
}

export default Table
