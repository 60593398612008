import Multiselect from 'multiselect-react-dropdown'
import styles from './Field.module.scss'
import React from 'react'
import PropTypes from 'prop-types'

export const MultiselectInput = ({ inputId, question, answer, onSelect, fieldRef }) => {
    const multiselectValue = question.options.filter(option => answer?.value?.includes(option.value))

    return (
        <Multiselect
            id={inputId}
            options={question.options}
            selectedValues={multiselectValue}
            onSelect={onSelect}
            onRemove={onSelect}
            displayValue="label"
            className={styles.multiselect}
            disable={answer?.disabled}
            ref={fieldRef}
            style={{
                multiselectContainer: {
                    width: '300px'
                },
                searchBox: {
                    whiteSpace: 'nowrap'
                },
                chips: {
                    background: '#909090'
                }
            }}
            placeholder="Mehrfachauswahl"
            emptyRecordMsg="Keine Einträge vorhanden"
        />
    )
}

MultiselectInput.propTypes = {
    inputId: PropTypes.any,
    question: PropTypes.any,
    answer: PropTypes.any,
    selectedValues: PropTypes.any,
    onSelect: PropTypes.func,
    fieldRef: PropTypes.any
}
