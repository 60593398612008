import cookie from 'cookie'
import { API_ENDPOINT } from '../config/constants'

const api = {
    async getSurveys (id) {
        const response = await fetch(`${API_ENDPOINT}/surveys/${id}`)
        return await response.json()
    },

    async getReport () {
        const response = await fetch(`${API_ENDPOINT}/report/`)
        return await response.json()
    },

    async setAnswer (answer) {
        const cookies = cookie.parse(document.cookie || '')
        const data = {
            survey_id: answer.survey_id,
            question_id: answer.question_id,
            product_id: answer.product_id,
            value: answer.value
        }
        try {
            const response = await fetch(`${API_ENDPOINT}/answers/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies?.csrftoken
                },
                body: JSON.stringify(data)
            })
            const body = await response.json()
            if (response.status === 201) {
                return { body }
            }
            return { error: true, errors: body?.errors }
        } catch (errors) {
            return {
                error: true,
                errors: [
                    errors.message
                ]
            }
        }
    }
}

export default api
