import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Field.module.scss'
import { Form } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import _ from 'underscore'
import { useAnswers } from '../../hooks/useAnswers'
import { getAnswerVariant } from '../../utils/getAnswerVariant'
import { useFieldCallbacks } from '../../hooks/useFieldCallbacks'
import { MultiselectInput } from './MultiselectInput'
import { SelectInput } from './SelectInput'
import { TextInput } from './TextInput'
import { AnswerButton } from './AnswerButton'

const useFieldLogic = (product, question, surveyId) => {
    const { getAnswer } = useAnswers()
    const answer = getAnswer(product.id, question.id)
    const isAnswered = typeof answer.id !== 'undefined' || answer.answered
    const isMultiselect = question?.type === 'multiselect'
    const { variant, icon } = getAnswerVariant(answer)
    const [inputId] = useState(_.uniqueId('input_'))
    const fieldRef = useRef(null)

    const { onMultiselectChange, onValueChange, resubmit, onBlur } = useFieldCallbacks({
        isMultiselect,
        answer,
        fieldRef,
        surveyId
    })
    return { answer, variant, icon, inputId, fieldRef, onValueChange, onBlur, onMultiselectChange, resubmit, isAnswered, isMultiselect }
}

const Field = ({ product, question, surveyId }) => {
    const {
        answer,
        variant,
        icon,
        inputId,
        fieldRef,
        onValueChange,
        onBlur,
        onMultiselectChange,
        resubmit,
        isAnswered,
        isMultiselect
    } = useFieldLogic(product, question, surveyId)

    return (
        <Form.Group>
            <InputGroup className={styles['input-group']}>
                {isAnswered && (
                    <AnswerButton variant={variant} icon={icon} surveyId={surveyId} product={product} question={question} resubmit={resubmit} />
                )}
                {['text', 'number'].includes(question.type) && (
                    <TextInput inputId={inputId} answer={answer} onValueChange={onValueChange} onBlur={onBlur} fieldRef={fieldRef} question={question} />
                )}
                {question.type === 'select' && (
                    <SelectInput inputId={inputId} answer={answer} question={question} onBlur={onBlur} fieldRef={fieldRef} onValueChange={onValueChange}/>
                )}
                {isMultiselect && (
                    <MultiselectInput inputId={inputId} question={question} onSelect={onMultiselectChange} answer={answer} fieldRef={fieldRef}/>
                )}
            </InputGroup>
        </Form.Group>
    )
}

Field.propTypes = {
    product: PropTypes.any,
    question: PropTypes.any,
    surveyId: PropTypes.number
}

export default Field
