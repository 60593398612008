import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Popover } from '../Popover'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PropTypes from 'prop-types'

export const AnswerButton = ({ variant, icon, surveyId, product, question, resubmit }) => (
    <OverlayTrigger trigger="click" rootClose placement="top" overlay={
        <Popover surveyId={surveyId} product={product} question={question} resubmit={resubmit}/>
    }>
        <Button variant={variant}>
            <FontAwesomeIcon icon={icon}/>
        </Button>
    </OverlayTrigger>
)

AnswerButton.propTypes = {
    variant: PropTypes.any,
    icon: PropTypes.any,
    surveyId: PropTypes.number,
    product: PropTypes.any,
    question: PropTypes.any,
    resubmit: PropTypes.func
}
