import { useContext } from 'react'
import { Context } from '../store'
import { DEFAULT_ANSWER } from '../config/constants'

export const useAnswers = () => {
    const [state] = useContext(Context)
    const getAnswer = (productId, questionId) => {
        const answer = state.answers?.[productId]?.[questionId]

        return answer ?? { ...DEFAULT_ANSWER, product_id: productId, question_id: questionId }
    }

    return { getAnswer }
}
