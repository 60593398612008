import { useCallback, useContext, useEffect, useState } from 'react'
import _ from 'underscore'
import api from '../services/api'
import { INPUT_DELAY } from '../config/constants'
import { Context } from '../store'

export const useFieldCallbacks = ({ answer, isMultiselect, fieldRef, surveyId }) => {
    const [, dispatch] = useContext(Context)
    const [focus, setFocus] = useState(false)

    const saveAnswer = useCallback(async (answer) => {
        let value = answer.value
        if (isMultiselect && value) {
            value = answer.value.map(option => option.value.toString())
        }

        const requestBody = {
            ...answer,
            survey_id: surveyId,
            value
        }
        const response = await api.setAnswer(requestBody)

        const payload = {
            ...answer,
            loading: false,
            errors: response?.errors,
            error: response?.error,
            ...response?.body,
            ...(!response?.error && { from_earlier_survey: false })
        }

        dispatch({ type: 'SET_ANSWER', payload })
    }, [dispatch, isMultiselect, surveyId])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveAnswerDebounced = useCallback(_.debounce(saveAnswer, INPUT_DELAY), [saveAnswer])

    const onChange = useCallback(({ value, disabled = false }) => {
        const newAnswer = {
            ...answer,
            value,
            disabled,
            loading: true,
            answered: true
        }
        dispatch({ type: 'SET_ANSWER', payload: newAnswer })
        saveAnswerDebounced(newAnswer)
    }, [answer, dispatch, saveAnswerDebounced])

    const resubmit = useCallback(() => {
        onChange(answer)
    }, [answer, onChange])

    const onValueChange = useCallback((event) => {
        onChange({ value: event.target.value })
    }, [onChange])

    const onMultiselectChange = useCallback((selectedList) => {
        onChange({ value: selectedList })
    }, [onChange])

    useEffect(() => {
        if (focus && typeof fieldRef?.current?.focus === 'function') {
            fieldRef?.current?.focus()
            setFocus(false)
        }
    }, [focus, fieldRef])

    const onBlur = () => {
        const isAnswered = typeof answer.id !== 'undefined' || answer.answered
        if (answer.loading || !isAnswered) {
            saveAnswerDebounced.cancel()
            saveAnswer({
                ...answer,
                answered: true,
                loading: true
            })
        }
    }

    return {
        onMultiselectChange,
        onValueChange,
        resubmit,
        onBlur
    }
}
